import React from "react";

const FixedBackgroundImage = ({ bgImage, bgColor, children }) => {
  return (
    <div
      style={{
        backgroundImage: `url(${bgImage})`,
        backgroundColor: bgColor,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "fixed",
        width: "100%",
        height: "100%",
        overflow: "hidden",
      }}
    >
      {children}
    </div>
  );
};

export default FixedBackgroundImage;
