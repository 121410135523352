import React from "react";
import { currency_symbol, fontName } from "../../../constants/utils";
import { useSelector } from "react-redux";

export default function ProductPricing(props) {
  const { product, price, discountedPrice } = props;
  const menuInfo = useSelector((state) => state.menuInfo);
  const {
    ui_strings,
    theme_data,
    price_decimal_places,
    display_currency,
    currency,
    currency_symbol: cSymbol,
  } = menuInfo.menuData;

  const productOldPriceStyle = {
    textDecoration: "line-through",
  };

  const productDiscountStyle = {
    color: theme_data?.product_screen_product_discount_text_color,
    fontSize: Number(theme_data?.product_screen_product_discount_font_size),
    ...fontName(theme_data?.product_screen_product_discount_font_family),
  };

  const productDiscountedPriceStyle = {
    color: theme_data?.product_screen_product_discount_price_text_color,
    fontSize: Number(
      theme_data?.product_screen_product_discount_price_font_size
    ),
    ...fontName(theme_data?.product_screen_product_discount_price_font_family),
  };

  return (
    <div>
      <span style={discountedPrice > 0 ? productOldPriceStyle : null}>
        {display_currency
          ? cSymbol
            ? cSymbol
            : currency_symbol(currency)
          : null}
        {parseFloat(price).toFixed(price_decimal_places)}
      </span>{" "}
      {discountedPrice > 0 ? (
        <span style={productDiscountedPriceStyle}>
          {display_currency
            ? cSymbol
              ? cSymbol
              : currency_symbol(currency)
            : null}
          {parseFloat(discountedPrice).toFixed(price_decimal_places)}
        </span>
      ) : null}
      {discountedPrice > 0 && (
        <div style={productDiscountStyle}>
          {product.discount}%{" "}
          {ui_strings?.ui_str_discount
            ? ui_strings.ui_str_discount
            : "Discount"}
        </div>
      )}
    </div>
  );
}
