import React, { useEffect } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Header from "components/Emenu/Header/Header.js";
import PaymentStyle from "./PaymentStyle";
import { useDispatch, useSelector } from "react-redux";
import firebase from "../../constants/Firebase";
import { orderClear } from "../../store/actions/cart";
import Lottie from "react-lottie-player";
import paymentSuccess from "../../assets/lotties/9912-payment-success.json";
import paymentFailed from "../../assets/lotties/9913-payment-failed.json";
import { fontName } from "../../constants/utils";
import { Helmet } from "react-helmet";
import CartButton from "../../components/Emenu/Cart/CartButton";

const useStyles = makeStyles(PaymentStyle);

export default function PaymentPage(props) {
  const menuInfo = useSelector((state) => state.menuInfo);
  const dispatch = useDispatch();
  const { ui_strings, theme_data, license } = menuInfo.menuData;
  const { reference, status } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    document.body.style.backgroundColor = theme_data?.cart_screen_bg_color;
    const rootElement = document.getElementById("root");
    if (rootElement) {
      rootElement.style.backgroundColor = theme_data?.cart_screen_bg_color;
    }
    if (license) {
      firebase.analytics().logEvent("payment_page_view", {
        license: license,
      });
    }

    // Clear previous order info
    dispatch(orderClear());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const classes = useStyles();

  const successTextStyle = {
    fontSize: Number(theme_data?.cart_screen_cart_success_font_size),
    color:
      status === "PAID"
        ? theme_data?.cart_screen_cart_success_text_color
        : "transparent",
    ...fontName(theme_data?.cart_screen_cart_success_font_family),
    textAlign: "center",
  };

  return (
    <div className={classes.mainContainer}>
      <Header brand={reference ? `Order ID - ${reference}` : `Order Payment`} />
      <Helmet>
        <title>
          {menuInfo.menuData?.name
            ? `${reference ? `Order ID - ${reference}` : `Order Payment`} | ${
                menuInfo.menuData?.name
              } | digitalemenu.com`
            : "digitalemenu.com"}
        </title>
      </Helmet>
      <div className={classNames(classes.toolbar, classes.main)}>
        <div className={classes.container}>
          <div style={successTextStyle}>
            {ui_strings?.ui_str_order_thanks
              ? ui_strings.ui_str_order_thanks
              : "Thank you for placing the order. Our team will get back to you shortly!"}
          </div>
          <Lottie
            loop={false}
            play={status !== "PENDING"}
            animationData={status === "PAID" ? paymentSuccess : paymentFailed}
            style={{ width: "90%", height: "90%" }}
          />
          <div
            className={classes.orderField}
            style={{ color: reference ? "inherit" : "transparent" }}
          >
            Order ID: {reference}
          </div>

          <div className={classes.orderField} style={{ marginBottom: 40 }}>
            Order Status: {status}
          </div>
          <CartButton noText />
        </div>
      </div>
    </div>
  );
}
